import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Legs",
  code: "Legs"
};
export const _frontmatter = {
  "menuLabel": "Legs",
  "sortOrder": 12.1
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Legs`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-icon--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Legs } from "@caesars-digital/caesars-ui";
`}</code></pre>
    <p>{`The Legs component can be used for any bulleted list where you'd like connected lines that show context. Each individual leg can have it's own custom bullet, which can be an `}<inlineCode parentName="p">{`Icon`}</inlineCode>{` or
`}<inlineCode parentName="p">{`TeamLogo`}</inlineCode>{`, as well as `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{` or a premade bullet style we've provided.`}</p>
    <p>{`There's also an options object that provides the capability to hide the top or bottom poles, or show the bullet/icon only without rendering the text.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10">
  <span className="text-red-600">*</span> indicates required.
    </div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
   The Legs component provides a clear visual grouping of bets with connected lines to show context. It utilizes appropriate color contrast and styling to ensure leg text, icons, and bullets are easily distinguishable. Users can perceive the connections and differentiate between various leg elements..
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
    The Legs component itself is not directly operable, but it can be interacted with indirectly through the associated content or actions. Users can navigate through the list of legs using standard keyboard or mouse interactions.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The purpose of the Legs component is clear, providing a visually organized representation of a bulleted list with connected lines. Each leg can have its own custom bullet, which can be an icon, team logo, avatar, or a pre-made bullet style. The text and icons within each leg convey relevant information.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    The Legs component is designed to be compatible with assistive technologies, ensuring that screen readers can access the leg text and associated information. It adheres to the provided properties, allowing customization of bullet styles, colors, and visibility of top and bottom poles. The component functions consistently across different browsers and platforms.
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      